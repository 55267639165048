/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */

/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */

*,
::before,
::after {
  background-repeat: no-repeat; /* 1 */
  box-sizing: inherit; /* 2 */
}

/**
  * 1. Add text decoration inheritance in all browsers (opinionated).
  * 2. Add vertical alignment inheritence in all browsers (opinionated).
  */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
  * 1. Add border box sizing in all browsers (opinionated).
  * 2. Add the default cursor in all browsers (opinionated).
  * 3. Prevent font size adjustments after orientation changes in IE and iOS.
  */

html {
  box-sizing: border-box; /* 1 */
  cursor: default; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
    ========================================================================== */

/**
  * Add the correct display in IE 9-.
  */

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
  * Remove the margin in all browsers (opinionated).
  */

body {
  margin: 0;
  background: pink;
}

/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
    ========================================================================== */

/**
  * Add the correct display in IE 9-.
  * 1. Add the correct display in IE.
  */

figcaption,
figure,
main {
  /* 1 */
  display: block;
}

/**
  * Add the correct margin in IE 8.
  */

figure {
  margin: 1em 40px;
}

/**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */

nav ol,
nav ul {
  list-style: none;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
    ========================================================================== */

/**
  * 1. Remove the gray background on active links in IE 10.
  * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
  */

a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
  * 1. Remove the bottom border in Firefox 39-.
  * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
  * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
  */

b,
strong {
  font-weight: inherit;
}

/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */

b,
strong {
  font-weight: bolder;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
  * Add the correct font style in Android 4.3-.
  */

dfn {
  font-style: italic;
}

/**
  * Add the correct background and color in IE 9-.
  */

mark {
  background-color: #ffff00;
  color: #000000;
}

/**
  * Add the correct font size in all browsers.
  */

small {
  font-size: 80%;
}

/**
  * Prevent `sub` and `sup` elements from affecting the line height in
  * all browsers.
  */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
  * Remove the text shadow on text selections (opinionated).
  * 1. Restore the coloring undone by defining the text shadow (opinionated).
  */

::-moz-selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
    ========================================================================== */

/*
  * Change the alignment on media elements in all browers (opinionated).
  */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
  * Add the correct display in IE 9-.
  */

audio,
video {
  display: inline-block;
}

/**
  * Add the correct display in iOS 4-7.
  */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
  * Remove the border on images inside links in IE 10-.
  */

img {
  border-style: none;
}

/**
  * Change the fill color to match the text color in all browsers (opinionated).
  */

svg {
  fill: currentColor;
}

/**
  * Hide the overflow in IE.
  */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
    ========================================================================== */

/**
  * Collapse border spacing
  */

table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
    ========================================================================== */

/**
  * Remove the margin in Firefox and Safari.
  */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
  * Inherit styling in all browsers (opinionated).
  */

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
  * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
  *    controls in Android 4.
  * 2. Correct the inability to style clickable types in iOS and Safari.
  */

button,
 html [type="button"], /* 1 */
 [type="reset"],
 [type="submit"] {
  -webkit-appearance: button; /* 2 */
}

/**
  * Remove the inner border and padding in Firefox.
  */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
  * Restore the focus styles unset by the previous rule.
  */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
  * 1. Add the correct display in IE 9-.
  * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
  * 1. Remove the default vertical scrollbar in IE.
  * 2. Change the resize direction on textareas in all browsers (opinionated).
  */

textarea {
  overflow: auto; /* 1 */
  resize: vertical; /* 2 */
}

/**
  * 1. Add the correct box sizing in IE 10-.
  * 2. Remove the padding in IE 10-.
  */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
  * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
  */

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
    ========================================================================== */

/*
  * Add the correct display in IE 9-.
  * 1. Add the correct display in Edge, IE, and Firefox.
  */

details, /* 1 */
 menu {
  display: block;
}

/*
  * Add the correct display in all browsers.
  */

summary {
  display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
    ========================================================================== */

/**
  * Add the correct display in IE 9-.
  */

canvas {
  display: inline-block;
}

/**
  * Add the correct display in IE.
  */

template {
  display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
    ========================================================================== */

/*
  * Remove the tapping delay on clickable elements (opinionated).
  * 1. Remove the tapping delay in IE 10.
  */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation;
}

/**
  * Add the correct display in IE 10-.
  */

[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
    ========================================================================== */

/**
  * Change the cursor on busy elements (opinionated).
  */

[aria-busy='true'] {
  cursor: progress;
}

/*
  * Change the cursor on control elements (opinionated).
  */

[aria-controls] {
  cursor: pointer;
}

/*
  * Change the display on visually hidden accessible elements (opinionated).
  */

[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements (opinionated).
  */

[aria-disabled] {
  cursor: default;
}

.defaultBrandButtonBlue{
  border-color: #3a5795;
  background-color: #3a5795;
  font-size: .875rem;
  color: #FFF;
  padding: .8rem;
  border-radius: 4px;
  border: none;
  min-height: 36px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.defaultBrandButtonRed{
  border-color: #ff5a5f;
  background-color: #ff5a5f !important;
  font-size: .875rem;
  color: #FFF;
  padding: .8rem;
  border-radius: 4px;
  border: none;
  min-height: 36px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.languageSelector {
  position: fixed;
  right: 11px;
  top: 68px;
  z-Index: 99999;
  width: 90px;
  padding: 5px 32px 5px 16px;

  @media (--viewportLarge) {
    top: 28px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1280px) { 
  .languageSelector {
    position: fixed;
    right: auto;
    top: 30px;
    left: 6px;
  }
}
@media screen and (min-width: 500px) and (max-width:1024px) { 
  .languageSelector {
    position: fixed;
    right: 60px;
    top: 10px;
    left: auto;
  }
}
@media screen and (min-width: 200px) and (max-width:500px) { 
  .languageSelector {
    top: 70px;
    display: none;
  }
}
.ant-pagination-item-active{
  height: auto !important;
}
.ant-table-content{
  border: #dadada solid 1px;
  border-radius: 8px;
  box-shadow: #00000021 1px 1px 10px 0px;
}
.ant-select-selection-search-input:focus-visible{
  box-shadow:none !important;
}
.ant-radio-button-wrapper-checked{
  background: #ff5a5f !important;
    color: #FFF !important;
    border: #ff5a5f !important;
}
.ant-radio-button-wrapper{
  width: 100% !important;
  text-align: center;
}
.ant-select-auto-complete{
  min-height: 38px;
}
sup{
  font-size: 12px;
  color: #ff5a5f;
}

.slider1 {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider1Prebooking {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}

.slide1-container {
  display: flex;
}

.slide1 {
  display: none;
}

.slide1.active {
  display: block;
  width: 100%;
}


.button1:first-child {
  left: 0;
}

.button1:last-child {
  right: 0;
}
.ant-space-gap-row-small{
  width: 100% !important;
}
.ant-collapse-item-active{
  & .ant-collapse-header{
    background: #1e1d85 !important;
    color: #fff  !important;
  }
}
.ant-collapse-header, .ant-collapse{
  border-radius: 0px !important;
  background: #FFF;
  color: #252525 !important;
  box-shadow: #00000024 0px 1px 6px 0px;
  font-size: 16px;
  font-weight: 500;
}
.ant-collapse-content-box{
  font-size: 14px;
  font-weight: 400;
  color: #696969 !important;
}

.mapboxgl-map {
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.mapboxgl-canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.mapboxgl-map:-webkit-full-screen {
  width: 100%;
  height: 100%;
}

.mapboxgl-canary {
  background-color: salmon;
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
  cursor: grab;
  -webkit-user-select: none;
  user-select: none;
}

.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-track-pointer {
  cursor: pointer;
}

.mapboxgl-canvas-container.mapboxgl-interactive:active,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass:active {
  cursor: grabbing;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate .mapboxgl-canvas {
  touch-action: pan-x pan-y;
}

.mapboxgl-canvas-container.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-drag-pan .mapboxgl-canvas {
  touch-action: pinch-zoom;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan .mapboxgl-canvas {
  touch-action: none;
}

.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right { position: absolute; pointer-events: none; z-index: 2; }
.mapboxgl-ctrl-top-left     { top: 0; left: 0; }
.mapboxgl-ctrl-top-right    { top: 0; right: 0; }
.mapboxgl-ctrl-bottom-left  { bottom: 0; left: 0; }
.mapboxgl-ctrl-bottom-right { right: 0; bottom: 0; }

.mapboxgl-ctrl {
  clear: both;
  pointer-events: auto;

  /* workaround for a Safari bug https://github.com/mapbox/mapbox-gl-js/issues/8185 */
  transform: translate(0, 0);
}
.mapboxgl-ctrl-top-left .mapboxgl-ctrl     { margin: 10px 0 0 10px; float: left; }
.mapboxgl-ctrl-top-right .mapboxgl-ctrl    { margin: 10px 10px 0 0; float: right; }
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl  { margin: 0 0 10px 10px; float: left; }
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl { margin: 0 10px 10px 0; float: right; }

.mapboxgl-ctrl-group {
  border-radius: 4px;
  background: #fff;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
}

@media (-ms-high-contrast: active) {
  .mapboxgl-ctrl-group:not(:empty) {
      box-shadow: 0 0 0 2px ButtonText;
  }
}

.mapboxgl-ctrl-group button {
  width: 29px;
  height: 29px;
  display: block;
  padding: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;
}

.mapboxgl-ctrl-group button + button {
  border-top: 1px solid #ddd;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (-ms-high-contrast: active) {
  .mapboxgl-ctrl-icon {
      background-color: transparent;
  }

  .mapboxgl-ctrl-group button + button {
      border-top: 1px solid ButtonText;
  }
}

.mapboxgl-ctrl-attrib-button:focus,
.mapboxgl-ctrl-group button:focus {
  box-shadow: 0 0 2px 2px rgb(0 150 255 / 100%);
}

.mapboxgl-ctrl button:disabled {
  cursor: not-allowed;
}

.mapboxgl-ctrl button:disabled .mapboxgl-ctrl-icon {
  opacity: 0.25;
}

.mapboxgl-ctrl-group button:first-child {
  border-radius: 4px 4px 0 0;
}

.mapboxgl-ctrl-group button:last-child {
  border-radius: 0 0 4px 4px;
}

.mapboxgl-ctrl-group button:only-child {
  border-radius: inherit;
}

.mapboxgl-ctrl button:not(:disabled) {
  /* background-color: rgb(0 0 0 / 5%); */
  display: none;
}

.mapboxgl-ctrl-group button:focus:focus-visible {
  box-shadow: 0 0 2px 2px rgb(0 150 255 / 100%);
}

.mapboxgl-ctrl-group button:focus:not(:focus-visible) {
  box-shadow: none;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: svg-load("svg/mapboxgl-ctrl-zoom-out.svg", fill: #333);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: svg-load("svg/mapboxgl-ctrl-zoom-in.svg", fill: #333);
}

@media (-ms-high-contrast: active) {
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-zoom-out.svg", fill: #fff);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-zoom-in.svg", fill: #fff);
  }
}

@media (-ms-high-contrast: black-on-white) {
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-zoom-out.svg", fill: #000);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-zoom-in.svg", fill: #000);
  }
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-image: svg-load("svg/mapboxgl-ctrl-fullscreen.svg", fill: #333);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
  background-image: svg-load("svg/mapboxgl-ctrl-shrink.svg");
}

@media (-ms-high-contrast: active) {
  .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-fullscreen.svg", fill: #fff);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-shrink.svg", fill: #fff);
  }
}

@media (-ms-high-contrast: black-on-white) {
  .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-fullscreen.svg", fill: #000);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-shrink.svg", fill: #000);
  }
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: svg-load("svg/mapboxgl-ctrl-compass.svg", fill: #333);
}

@media (-ms-high-contrast: active) {
  .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
      @svg-load ctrl-compass-white url("svg/mapboxgl-ctrl-compass.svg") {
          fill: #fff;
          #south { fill: #999; }
      }

      background-image: svg-inline(ctrl-compass-white);
  }
}

@media (-ms-high-contrast: black-on-white) {
  .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
      background-image: svg-load("svg/mapboxgl-ctrl-compass.svg", fill: #000);
  }
}

@svg-load ctrl-geolocate url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #333;
  #stroke { display: none; }
}

@svg-load ctrl-geolocate-white url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #fff;
  #stroke { display: none; }
}

@svg-load ctrl-geolocate-black url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #000;
  #stroke { display: none; }
}

@svg-load ctrl-geolocate-disabled url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #aaa;
  #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-disabled-white url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #999;
  #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-disabled-black url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #666;
  #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-active url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #33b5e5;
  #stroke { display: none; }
}

@svg-load ctrl-geolocate-active-error url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #e58978;
  #stroke { display: none; }
}

@svg-load ctrl-geolocate-background url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #33b5e5;
  #stroke { display: none; }
  #dot { display: none; }
}

@svg-load ctrl-geolocate-background-error url("svg/mapboxgl-ctrl-geolocate.svg") {
  fill: #e54e33;
  #stroke { display: none; }
  #dot { display: none; }
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: svg-inline(ctrl-geolocate);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:disabled .mapboxgl-ctrl-icon {
  background-image: svg-inline(ctrl-geolocate-disabled);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon {
  background-image: svg-inline(ctrl-geolocate-active);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active-error .mapboxgl-ctrl-icon {
  background-image: svg-inline(ctrl-geolocate-active-error);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background .mapboxgl-ctrl-icon {
  background-image: svg-inline(ctrl-geolocate-background);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background-error .mapboxgl-ctrl-icon {
  background-image: svg-inline(ctrl-geolocate-background-error);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-waiting .mapboxgl-ctrl-icon {
  animation: mapboxgl-spin 2s infinite linear;
}

@media (-ms-high-contrast: active) {
  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
      background-image: svg-inline(ctrl-geolocate-white);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:disabled .mapboxgl-ctrl-icon {
      background-image: svg-inline(ctrl-geolocate-disabled-white);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon {
      background-image: svg-inline(ctrl-geolocate-active);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active-error .mapboxgl-ctrl-icon {
      background-image: svg-inline(ctrl-geolocate-active-error);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background .mapboxgl-ctrl-icon {
      background-image: svg-inline(ctrl-geolocate-background);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background-error .mapboxgl-ctrl-icon {
      background-image: svg-inline(ctrl-geolocate-background-error);
  }
}

@media (-ms-high-contrast: black-on-white) {
  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
      background-image: svg-inline(ctrl-geolocate-black);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:disabled .mapboxgl-ctrl-icon {
      background-image: svg-inline(ctrl-geolocate-disabled-black);
  }
}

@keyframes mapboxgl-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

a.mapboxgl-ctrl-logo {
  width: 88px;
  height: 23px;
  margin: 0 0 -4px -4px;
  display: block;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  background-image: svg-load("svg/mapboxgl-ctrl-logo.svg");
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
  width: 23px;
}

@media (-ms-high-contrast: active) {
  a.mapboxgl-ctrl-logo {
      @svg-load ctrl-logo-white url("svg/mapboxgl-ctrl-logo.svg") {
          #outline { opacity: 1; }
          #fill { opacity: 1; }
      }

      background-color: transparent;
      background-image: svg-inline(ctrl-logo-white);
  }
}

@media (-ms-high-contrast: black-on-white) {
  a.mapboxgl-ctrl-logo {
      @svg-load ctrl-logo-black url("svg/mapboxgl-ctrl-logo.svg") {
          #outline { opacity: 1; fill: #fff; stroke: #fff; }
          #fill { opacity: 1; fill: #000; }
      }

      background-image: svg-inline(ctrl-logo-black);
  }
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  padding: 0 5px;
  background-color: rgb(255 255 255 / 50%);
  margin: 0;
}

@media screen {
  .mapboxgl-ctrl-attrib.mapboxgl-compact {
      min-height: 20px;
      padding: 2px 24px 2px 0;
      margin: 10px;
      position: relative;
      background-color: #fff;
      border-radius: 12px;
      box-sizing: content-box;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact-show {
      padding: 2px 28px 2px 8px;
      visibility: visible;
  }

  .mapboxgl-ctrl-top-left > .mapboxgl-ctrl-attrib.mapboxgl-compact-show,
  .mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl-attrib.mapboxgl-compact-show {
      padding: 2px 8px 2px 28px;
      border-radius: 12px;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-inner {
      display: none;
  }

  .mapboxgl-ctrl-attrib-button {
      display: none;
      cursor: pointer;
      position: absolute;
      background-image: svg-load("svg/mapboxgl-ctrl-attrib.svg");
      background-color: rgb(255 255 255 / 50%);
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 12px;
      outline: none;
      top: 0;
      right: 0;
      border: 0;
  }

  .mapboxgl-ctrl-top-left .mapboxgl-ctrl-attrib-button,
  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-attrib-button {
      left: 0;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-button,
  .mapboxgl-ctrl-attrib.mapboxgl-compact-show .mapboxgl-ctrl-attrib-inner {
      display: block;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact-show .mapboxgl-ctrl-attrib-button {
      background-color: rgb(0 0 0 / 5%);
  }

  .mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
      bottom: 0;
      right: 0;
  }

  .mapboxgl-ctrl-top-right > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
      top: 0;
      right: 0;
  }

  .mapboxgl-ctrl-top-left > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
      top: 0;
      left: 0;
  }

  .mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
      bottom: 0;
      left: 0;
  }
}

@media screen and (-ms-high-contrast: active) {
  .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
      background-image: svg-load("svg/mapboxgl-ctrl-attrib.svg", fill=#fff);
  }
}

@media screen and (-ms-high-contrast: black-on-white) {
  .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
      background-image: svg-load("svg/mapboxgl-ctrl-attrib.svg");
  }
}

.mapboxgl-ctrl-attrib a {
  color: rgb(0 0 0 / 75%);
  text-decoration: none;
}

.mapboxgl-ctrl-attrib a:hover {
  color: inherit;
  text-decoration: underline;
}

/* stylelint-disable-next-line selector-class-pattern */
.mapboxgl-ctrl-attrib .mapbox-improve-map {
  font-weight: bold;
  margin-left: 2px;
}

.mapboxgl-attrib-empty {
  display: none;
}

.mapboxgl-ctrl-scale {
  background-color: rgb(255 255 255 / 75%);
  font-size: 10px;
  border-width: medium 2px 2px;
  border-style: none solid solid;
  border-color: #333;
  padding: 0 5px;
  color: #333;
  box-sizing: border-box;
  white-space: nowrap;
}

.mapboxgl-popup {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  will-change: transform;
  pointer-events: none;
}

.mapboxgl-popup-anchor-top,
.mapboxgl-popup-anchor-top-left,
.mapboxgl-popup-anchor-top-right {
  flex-direction: column;
}

.mapboxgl-popup-anchor-bottom,
.mapboxgl-popup-anchor-bottom-left,
.mapboxgl-popup-anchor-bottom-right {
  flex-direction: column-reverse;
}

.mapboxgl-popup-anchor-left {
  flex-direction: row;
}

.mapboxgl-popup-anchor-right {
  flex-direction: row-reverse;
}

.mapboxgl-popup-tip {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  z-index: 1;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  align-self: center;
  border-top: none;
  border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  align-self: flex-start;
  border-top: none;
  border-left: none;
  border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  align-self: flex-end;
  border-top: none;
  border-right: none;
  border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  align-self: center;
  border-bottom: none;
  border-top-color: #fff;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  align-self: flex-start;
  border-bottom: none;
  border-left: none;
  border-top-color: #fff;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  align-self: flex-end;
  border-bottom: none;
  border-right: none;
  border-top-color: #fff;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  align-self: center;
  border-left: none;
  border-right-color: #fff;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  align-self: center;
  border-right: none;
  border-left-color: #fff;
}

.mapboxgl-popup-close-button {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  background-color: transparent;
}

.mapboxgl-popup-close-button:hover {
  background-color: rgb(0 0 0 / 5%);
}

.mapboxgl-popup-content {
  position: relative;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 10px 10px 15px;
  pointer-events: auto;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
  border-top-left-radius: 0;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
  border-top-right-radius: 0;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
  border-bottom-left-radius: 0;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
  border-bottom-right-radius: 0;
}

.mapboxgl-popup-track-pointer {
  display: none;
}

.mapboxgl-popup-track-pointer * {
  pointer-events: none;
  user-select: none;
}

.mapboxgl-map:hover .mapboxgl-popup-track-pointer {
  display: flex;
}

.mapboxgl-map:active .mapboxgl-popup-track-pointer {
  display: none;
}

.mapboxgl-marker {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  opacity: 1;
  transition: opacity 0.2s;
}

.mapboxgl-user-location-dot {
  background-color: #1da1f2;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.mapboxgl-user-location-dot::before {
  background-color: #1da1f2;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  animation: mapboxgl-user-location-dot-pulse 2s infinite;
}

.mapboxgl-user-location-dot::after {
  border-radius: 50%;
  border: 2px solid #fff;
  content: "";
  height: 19px;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 19px;
  box-sizing: border-box;
  box-shadow: 0 0 3px rgb(0 0 0 / 35%);
}

.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading {
  width: 0;
  height: 0;
}

.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading::before,
.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading::after {
  content: "";
  border-bottom: 7.5px solid #4aa1eb;
  position: absolute;
}

.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading::before {
  border-left: 7.5px solid transparent;
  transform: translate(0, -28px) skewY(-20deg);
}

.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading::after {
  border-right: 7.5px solid transparent;
  transform: translate(7.5px, -28px) skewY(20deg);
}

@keyframes mapboxgl-user-location-dot-pulse {
  0%   { transform: scale(1); opacity: 1; }
  70%  { transform: scale(3); opacity: 0; }
  100% { transform: scale(1); opacity: 0; }
}

.mapboxgl-user-location-dot-stale {
  background-color: #aaa;
}

.mapboxgl-user-location-dot-stale::after {
  display: none;
}

.mapboxgl-user-location-accuracy-circle {
  background-color: #1da1f233;
  width: 1px;
  height: 1px;
  border-radius: 100%;
}

.mapboxgl-crosshair,
.mapboxgl-crosshair .mapboxgl-interactive,
.mapboxgl-crosshair .mapboxgl-interactive:active {
  cursor: crosshair;
}

.mapboxgl-boxzoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background: #fff;
  border: 2px dotted #202020;
  opacity: 0.5;
}

@media print {
  /* stylelint-disable-next-line selector-class-pattern */
  .mapbox-improve-map {
      display: none;
  }
}

.mapboxgl-touch-pan-blocker,
.mapboxgl-scroll-zoom-blocker {
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  justify-content: center;
  text-align: center;
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 70%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.75s ease-in-out;
  transition-delay: 1s;
}

.mapboxgl-touch-pan-blocker-show,
.mapboxgl-scroll-zoom-blocker-show {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.mapboxgl-canvas-container.mapboxgl-touch-pan-blocker-override.mapboxgl-scrollable-page,
.mapboxgl-canvas-container.mapboxgl-touch-pan-blocker-override.mapboxgl-scrollable-page .mapboxgl-canvas {
  touch-action: pan-x pan-y;
}

.detailed-search{
  position: absolute;
  margin: auto;
  display: flex;
  border-radius: 10px;
  /* overflow: hidden; */
  box-shadow: #3a5795 1px 0px 9px 0px;
  /* margin-left: auto; */
  background: white;
  /* min-width: 640px; */
}

.search-container{
  display: flex;
  justify-content: center;
}

.ant-tooltip-placement-bottom{
  z-index: 9999999999;
}
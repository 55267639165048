@import '../../../styles/customMediaQueries.css';

.bookingBreakdownTitle {
  /* letter-spacing: 1px;
  font-weight: var(--fontWeightMedium); */
  font-weight: 600;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--colorGrey100);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.quantityField {
  padding: 0 24px;
  width: 50%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.firstNameField {
  padding: 0 24px;
  width: 53%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.emailField {
  padding: 0 24px;
  width: 50%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.payerNameField {
  padding: 0 24px;
  width: 53%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionContainer {
  width: 283px;
}

.locationDiv {
  width: 283px;
}

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodLabel {
  composes: label from global;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.formRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
  gap: 20px;
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.locationAutocompleteInput {
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
}

.couponDiv {
  display: flex;
  gap: 20px;
}

.applyButton {
  width: 100px;
  min-height: 32px;
}

.couponField {
  width: 370px;
}

.anchor {
  font-weight: 80px;
}

.termsText {

}

.checkboxDiv {
  display: flex;
  margin-top: 5px;
}
.invalidCouponmessage{
  color: red;;
}
.successCouponmessage{
  padding: 2px;
}
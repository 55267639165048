@import '../../styles/customMediaQueries.css';
.root {
  width: 100%;
  height: 100%;
  background-color: var(--colorGrey100);
}

.mapRoot {
  width: 100%;
  height: 100%;
}


.locationTravelmodes{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between; 

  & button{
    border:none;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    background: #3a5795;
    padding: 5px 14px;

    &:hover{
background: #ff5a5f ;
    }
    &svg{
      color: #FFF;
    }
  }
}

.mapFilterSection{
  display: flex;
  align-items: center;
  gap: 16px;
}

.locationItem{
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;

  @media (--viewportMedium) {
  flex-direction: row;
  }

}
.locationValues{
  display: flex;
  flex-direction: column;
  

  & p{
    margin: 0px;
    padding: 0px;
  }
  
}
